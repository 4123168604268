import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Samples from '../components/content/sample/Samples'
import withTranslations from '../components/hoc/withTranslations'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'
import { IProductTemplateData } from '../types/Product'

class SamplesPageTemplate extends React.Component<Props, {}> {
    public render() {
        const { pageContext, t } = this.props
        const { templateData } = pageContext

        return (
            <MainLayout pageContext={pageContext}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h1>{t('template', 'sample.page.title')}</h1>
                        </Col>
                    </Row>
                </Container>

                {templateData?.samples && <Samples productSample={templateData.samples} />}
            </MainLayout>
        )
    }
}

export default withTranslations(SamplesPageTemplate)

interface Props extends Translation {
    pageContext: PageContext<IProductTemplateData>
}
