import * as React from 'react'
import hljs from 'highlight.js'

class HighlightedCode extends React.Component<HighlightedCodeProps> {
    codeRef: React.RefObject<HTMLElement>

    constructor(props: HighlightedCodeProps) {
        super(props)
        this.codeRef = React.createRef()
    }

    componentDidMount = () => this.doHighlight()
    componentDidUpdate = () => this.doHighlight()

    doHighlight = () => {
        if (this.codeRef.current) {
            // @ts-ignore
            hljs.highlightElement(this.codeRef.current)
        }
    }

    render = () => {
        const { codeLanguage, code } = this.props

        return (
            <pre>
                <code
                    ref={this.codeRef}
                    className={codeLanguage ? `lang-${codeLanguage}` : undefined}
                    dangerouslySetInnerHTML={{ __html: code }}
                />
            </pre>
        )
    }
}

export default HighlightedCode

interface HighlightedCodeProps {
    codeLanguage?: string
    code: string
}
