import * as React from 'react'
import { Col, Container, Nav, Row, TabContainer, TabContent, TabPane } from 'react-bootstrap'
import { SelectCallback } from 'react-bootstrap/helpers'
import TabBoxTab from '../../../types/Tab'
import ColorBox from '../../content/ColoredBox'
import * as styles from './TabBox.module.scss'

const TabBox: React.FunctionComponent<TabBoxProps> = (props: TabBoxProps) => {
    const { tabs, pdftColor, defaultActiveKey, activeKey, onTabSelect } = props

    const tabColor = pdftColor ? `nav-link-${pdftColor}` : undefined

    return (
        <TabContainer activeKey={activeKey} defaultActiveKey={defaultActiveKey} onSelect={onTabSelect}>
            <Container className="my-0">
                <Row>
                    <Col sm={12}>
                        <Nav variant="tabs">
                            {tabs.map((tab) => (
                                <Nav.Item className={styles.navItem} key={`nav-tab-${tab.id}`}>
                                    {tab.link ? (
                                        tab.header
                                    ) : (
                                        <Nav.Link eventKey={tab.id} className={`${styles.tabLink} ${tabColor}`}>
                                            {tab.header}
                                        </Nav.Link>
                                    )}
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Col>
                </Row>
            </Container>
            <ColorBox pdftColor={pdftColor} fullWidth={true} padding={false} positionStatic={true}>
                <TabContent>
                    {tabs
                        .filter((t) => !t.link)
                        .map((tab) => (
                            <TabPane eventKey={tab.id} key={`tabpane-${tab.id}`} className={styles.tabPane}>
                                {tab.content}
                            </TabPane>
                        ))}
                </TabContent>
            </ColorBox>
        </TabContainer>
    )
}

interface TabBoxProps {
    tabs: TabBoxTab[]
    pdftColor?: string
    defaultActiveKey: string
    activeKey?: string
    onTabSelect?: SelectCallback
}

export default TabBox
