import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IProductSampleCategory } from '../../../types/Product'
import SampleBox from './SampleBox'

const SampleCategory: React.FunctionComponent<SampleCategoryProps> = (props: SampleCategoryProps) => {
    const { sampleCategory, categoryId, productCode, productShortname } = props

    return (
        <div className="mb-4" id={categoryId}>
            <Container className="my-0">
                <Row>
                    <Col sm={12}>
                        <h2>{sampleCategory.categoryName}</h2>
                    </Col>
                </Row>
            </Container>
            {sampleCategory.useCases.map((useCase, i) => (
                <>
                    {i > 0 && (
                        <Container className="my-0">
                            <Row>
                                <Col sm={12}>
                                    <hr key={`hr-${i}`} />
                                </Col>
                            </Row>
                        </Container>
                    )}

                    <SampleBox productCode={productCode} productShortname={productShortname} useCase={useCase} />
                </>
            ))}
        </div>
    )
}

interface SampleCategoryProps {
    sampleCategory: IProductSampleCategory
    categoryId: string
    productCode: string
    productShortname: string
}

export default SampleCategory
