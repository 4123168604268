import { Link } from 'gatsby'
import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import TabBoxTab from '../../../types/Tab'
import TabBox from '../../content/tabbox/TabBox'
import withTranslations from '../../hoc/withTranslations'
import Translation from '../../../types/Translation'
import { IProductSampleUseCase, IProductSample } from '../../../types/Product'
import * as styles from './SampleBox.module.scss'
import HighlightedCode from './HighlightedCode'
import SampleDownloadButton from './SampleDownloadButton'

const interfaces: Record<string, { name: string; icon: string; hlJs: string }> = {
    cs: { name: 'C#', icon: 'pti-api-cs', hlJs: 'cs' },
    c: { name: 'C', icon: 'pti-api-c', hlJs: 'c' },
    java: { name: 'Java', icon: 'pti-api-java', hlJs: 'java' },
    js: { name: 'JavaScript', icon: 'pti-api-js', hlJs: 'js' },
}

const platforms: Record<string, { name: string; icon: string }> = {
    windows: { name: 'Windows', icon: 'pti-os-windows' },
    'linux-glibc': { name: 'Linux glibc', icon: 'pti-os-linux' },
    macos: { name: 'macOS', icon: 'pti-os-apple' },
}

class SampleBox extends React.Component<SampleBoxProps, SampleBoxState> {
    btnRefs: IBtnRefs = {}
    activeHash: boolean = false

    constructor(props: SampleBoxProps) {
        super(props)

        this.state = {
            activeTab: undefined,
            firstLoading: true,
        }
    }

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll)

        const { useCase } = this.props

        if (typeof window !== `undefined` && window.location.hash === `#sample_${useCase.shortName}` && this.state.firstLoading) {
            this.setState({ activeTab: 'CS', firstLoading: false })
            this.activeHash = true
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        if (this.state.activeTab) {
            const btnRef = this.btnRefs[this.state.activeTab]

            const topBtnRef = btnRef.top.current
            const bottomBtnRef = btnRef.bottom.current

            const currentScrollTop = document.documentElement.scrollTop

            if (currentScrollTop > topBtnRef.offsetTop + 35) {
                if (!bottomBtnRef.classList.contains('show-download-btn')) {
                    bottomBtnRef.classList.add('show-download-btn')
                }
            } else {
                if (bottomBtnRef.classList.contains('show-download-btn')) {
                    bottomBtnRef.classList.remove('show-download-btn')
                }
            }
        }
    }

    handleTabSelect = (selectedTab: string | undefined) => {
        if (selectedTab === this.state.activeTab) {
            selectedTab = undefined // close tab if clicked on active tab
        }
        this.setState({ activeTab: selectedTab })
    }

    render = () => {
        const { useCase, toMoreSamples, firstTabActive, t } = this.props
        const tabs: TabBoxTab[] = useCase.samples.map((sample) => ({
            header: <span className={`${getInterface(sample.interface)?.icon} ${styles.icon}`} />,
            content: this.createTabContent(sample),
            link: false,
            id: sample.interface,
        }))

        let defaultActiveKey = ''
        if (firstTabActive && tabs[0]?.id) {
            defaultActiveKey = tabs[0].id // setting first tab as active
        }

        if (toMoreSamples) {
            tabs.push({
                header: (
                    <Link to={toMoreSamples} className={`btn btn-primary btn-upper violet ${styles.btnMoreSamples}`}>
                        {t('template', 'sample.link.text.moresample')}
                    </Link>
                ),
                link: true,
            })
        }

        return (
            <div id={`sample_${useCase.shortName}`} className={styles.useCase}>
                <Container className="my-0">
                    <Row>
                        <Col sm={12}>
                            <h3>
                                <a href={`#sample_${useCase.shortName}`} className={styles.anchorLink} area-hidden="true">
                                    <span className={'pti-link'} />
                                </a>
                                {useCase.title}
                            </h3>
                            <p>{useCase.description}</p>
                        </Col>
                    </Row>
                </Container>

                <TabBox
                    pdftColor={'gray'}
                    tabs={tabs}
                    defaultActiveKey={defaultActiveKey}
                    activeKey={this.state.activeTab}
                    onTabSelect={this.handleTabSelect}
                />
            </div>
        )
    }

    createTabContent = (sample: IProductSample): React.ReactNode => {
        const sampleInterface = getInterface(sample.interface)
        const downloadButtons = this.createDownloadButtons(sample)

        const bottomBtnRef = React.createRef<HTMLDivElement>()
        const topBtnRef = React.createRef<HTMLDivElement>()

        this.btnRefs[sample.interface] = {
            top: topBtnRef,
            bottom: bottomBtnRef,
        }

        return (
            <>
                <div className="text-right" ref={topBtnRef}>
                    <span className="pti-download" />
                    <span>{sampleInterface?.name} sample:</span>
                    <div className="d-inline-block">{downloadButtons}</div>
                </div>
                {sample.codeSnippets.map((snippet, i) => (
                    <HighlightedCode key={i} codeLanguage={sampleInterface?.hlJs} code={snippet} />
                ))}
                <div ref={bottomBtnRef} className={`${styles.bottomDownloadBtn} text-right`}>
                    <span className="pti-download" />
                    <span>{sampleInterface?.name} sample:</span>
                    <div className="d-inline-block">{downloadButtons}</div>
                </div>
            </>
        )
    }

    createDownloadButtons = (sample: IProductSample): React.ReactNode[] => {
        const { productCode, useCase } = this.props

        const downloadButtons: React.ReactNode[] = []

        const sampleUrl = `/api/v1.0/Samples/_${productCode}/${useCase.shortName}/Download/?technology=${sample.interface}`

        if (sample.platforms) {
            sample.platforms.forEach((platform) => {
                const url = `${sampleUrl}&platform=${platform}`

                downloadButtons.push(
                    <SampleDownloadButton
                        downloadUrl={url}
                        platform={getPlatform(platform)}
                        technology={getInterface(sample.interface) || { name: '??' }}
                    />,
                )
            })
        } else {
            const url = sampleUrl
            downloadButtons.push(
                <SampleDownloadButton downloadUrl={url} technology={getInterface(sample.interface) || { name: '??' }} />,
            )
        }

        return downloadButtons
    }
}

export default withTranslations(SampleBox)

const getInterface = (interf: string): { name: string; icon: string; hlJs: string } | undefined => {
    interf = interf.toLowerCase()
    for (const key in interfaces) {
        if (interfaces.hasOwnProperty(key) && interf.startsWith(key)) {
            return interfaces[key]
        }
    }
    return undefined
}

const getPlatform = (platform: string): { name: string; icon: string } | undefined => {
    platform = platform.toLowerCase()
    for (const key in platforms) {
        if (platforms.hasOwnProperty(key) && platform.startsWith(key)) {
            return platforms[key]
        }
    }
    return undefined
}

interface SampleBoxProps extends Translation {
    productCode: string
    productShortname: string
    useCase: IProductSampleUseCase
    firstTabActive?: boolean
    toMoreSamples?: string
}

interface SampleBoxState {
    activeTab?: string
    firstLoading: boolean
}

interface IBtnRefs {
    [key: string]: { bottom: React.RefObject<any>; top: React.RefObject<any> }
}
