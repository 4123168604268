import * as React from 'react'
import ContentButton from '../ContentButton'

class SampleDownloadButton extends React.Component<SampleDownloadButtonProps> {
    render = (): React.ReactNode => {
        const { platform, technology } = this.props

        let label = <span className="pti-download" />
        let title = `Download ${technology} sample`

        if (platform) {
            label = <span className={platform.icon} />
            title += ` for ${platform.name}`
        }

        return (
            <ContentButton onClick={this.handleClick} className="ml-2" title={title}>
                {label}
            </ContentButton>
        )
    }

    handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        window.open(this.props.downloadUrl, '_blank')
    }
}

export default SampleDownloadButton

interface SampleDownloadButtonProps {
    downloadUrl: string
    platform?: { name: string; icon: string }
    technology: { name: string }
}
