import * as React from 'react'
import { Container } from 'react-bootstrap'
import AnchorLink from '../AnchorLink'
import AnchorNavigation from '../AnchorNavigation'

import { IProductSamples } from '../../../types/Product'
import SampleCategory from './SampleCategory'

const Samples: React.FunctionComponent<SamplesProps> = (props: SamplesProps) => {
    const { productSample } = props

    const finalCategories: JSX.Element[] = []
    const anchorLinks: JSX.Element[] = []

    productSample?.categories.forEach((category, i) => {
        const categoryId = `cat${i}`

        anchorLinks.push(<AnchorLink anchor={`#${categoryId}`} anchorKey={category.categoryName} key={`anchor-${i}`} />)

        finalCategories.push(
            <SampleCategory
                sampleCategory={category}
                key={categoryId}
                categoryId={categoryId}
                productCode={productSample.productCode}
                productShortname={productSample.productShortname}
            />,
        )
    })

    return (
        <>
            <Container className="my-0">
                <AnchorNavigation>{anchorLinks}</AnchorNavigation>
            </Container>
            {finalCategories}
        </>
    )
}

interface SamplesProps {
    productSample: IProductSamples
}

export default Samples
